.header-menu {
  background-color: var(--allmost-black-2);
  color: white;
  padding: 20px;
  padding-top: 0;
  flex-basis: 300px;
  min-width: 300px;
  font-size: 0.9rem;
  z-index: -1;
  min-height: 100%;
  max-width: 560px;
  margin-left: auto;
  margin-right: auto;
  /* overflow-x:scroll; */
}

.header-menu__sticky {
  /* position: sticky;
  top: 20px;
  padding-right: 50px;
  margin-right: -50px;   */
}

@media (max-width: 1024px) {
  /* .header-menu{
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(-500px);
    width: 350px;
    max-width: 50vw;
  }
  .header-menu__sticky {
    position: static;
    margin: 0;
    top: 0;
    padding:0;
  } */
}
.header-menu__sticky > *:first-child {
  margin-top: 0;
}
.app-title {
  margin-bottom: 0.5em;
  color: var(--green);
}
@media (max-width: 600px) {
  .app-title {
    margin: 0;
  }
}

.app-title:first-child {
  margin: 0;
}

.navigation-block {
  columns: 3;
}

.navigation-block__list {
  margin: 0;
  padding: 0;
}

.navigation-block__list-item {
  list-style-type: none;
  color: var(--pink);
}

.app-link-in-text {
  color: var(--green);
  font-weight: bold;
  text-decoration: none;
}
