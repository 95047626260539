.app {
  /* display: flex; */
  position: relative;
  /* background-color: var(--anthracite); */
}
.app-view {
  width: 100vw;
}

.hidden {
  display: none !important;
}

img {
  max-width: 100%;
  height: auto;
}

iframe {
  border: 0;
}

blockquote {
  font-size: 0.75em;
  font-style: italic;
  margin: 1em 1.5em;
}
