.event-block__paragraph {
  font-size: 0.8em;
  line-height: 0.95;
  max-width: 55ch;
}

.event-block iframe {
  max-width: 100%;
}

/* #region WRAPPER */

.event-block__wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  overflow-x: hidden;
}

@media (max-width: 600px) {
  body .event-block__wrapper {
    display: block;
  }
}
/* #endregion WRAPPER */

/* #region SELF */

.event-block {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  flex-basis: calc(20% - 3em);
  min-height: 300px;
  min-width: 300px;
  padding: 1.5em;
  position: relative;
  flex-shrink: 1;
  position: relative;
  overflow-x: hidden;
}
.event-block--long-title:not(.event-block--enlarged) {
  flex-basis: calc(27% - 3em);
}
.event-block--short-title
  + .event-block--short-title
  + .event-block--short-title:not(.event-block--enlarged) {
  flex-basis: 30%;
}
.event-block--sold-out:not(.event-block--enlarged),
.event-block--short-title
  + .event-block--short-title
  + .event-block--short-title.event-block--sold-out:not(.event-block--enlarged) {
  flex-basis: 15%;
}
.event-block--short-title.event-block--tiny-title:not(.event-block--enlarged) {
  flex-basis: calc(22%);
}
@media (min-width: 1024px) {
  .event-block.event-block--random-style:not(.event-block--enlarged),
  .event-block.event-block--random-style.event-block--short-title:not(.event-block--enlarged) {
    flex-basis: calc(19%);
    transform: rotate(1deg) scale(1.05);
  }
  .event-block.event-block--random-style-2.event-block.event-block--random-style:not(
      .event-block--enlarged
    ),
  .event-block.event-block--random-style-2.event-block--short-title.event-block.event-block--random-style:not(
      .event-block--enlarged
    ) {
    transform: rotate(-1deg) scale(1.05);
  }
  .event-block.event-block--random-style-3.event-block.event-block--random-style:not(
      .event-block--enlarged
    ),
  .event-block.event-block--random-style-3.event-block--short-title.event-block.event-block--random-style:not(
      .event-block--enlarged
    ) {
    transform: rotate(0.5deg) scale(1.025);
  }
}

.event-block:hover {
  cursor: pointer;
}

@media (max-width: 600px) {
  .event-block {
    min-height: 170px;
    padding: 1em;
    width: 100%;
    min-width: initial;
    max-width: calc(100% - 2em);
  }
  .event-block--sold-out {
    min-height: 100px;
  }
}
@media (min-width: 1024px) {
  .event-block {
    box-shadow: inset -5px -5px 7px 1px #0f110e99;
  }
  .event-block--enlarged {
    box-shadow: initial;
  }
}

.event-block--enlarged {
  width: 100vw;
  height: auto;
  min-height: calc(50vh - 3em);
  max-height: initial;
  max-width: 100%;
  font-size: 1.5rem;
  overflow: hidden;
  flex-basis: 100%;
  padding-top: 3em;
  padding-bottom: 0;
  margin-bottom: 0;
}

@media (max-width: 600px) {
  .event-block--enlarged {
    width: calc(100vw - 2em);
    min-height: calc(100vh - 2em);
    max-height: initial;
    max-width: 100%;
    font-size: 1rem;
    overflow: hidden;
    padding-top: 3em;
  }
}
@media (min-width: 1024px) {
  .event-block.event-block--enlarged {
    max-width: 700px;
    filter: initial;
    position: absolute;
    left: 50%;
    z-index: 1000;
    transform: translate(-50%, 0%);
    overflow: visible;
  }
}

.some-event-is-enlarged .event-block:not(.event-block--enlarged) {
  filter: brightness(30%) blur(2px);
  transform: scale(1.02);
}
.some-event-is-enlarged .event-block--enlarged {
  filter: initial;
}

/* #endregion SELF */

/* #region HEADER ALL */

.event-block__header {
  margin-bottom: 0.5em;
}
@media (min-width: 1024px) {
  .event-block__header--enlarged {
    margin-bottom: 0;
  }
}

.event-block__header--sold-out {
  filter: brightness(50%);
  font-size: 0.66em;
}
.event-block__header--sold-out.event-block__header--enlarged {
  filter: brightness(100%);
}

@media (max-width: 600px) {
  .event-block__header--enlarged {
    max-width: 100%;
  }
}

.event-block__paragraph--short-text {
  margin-top: 0.33em;
  font-size: 0.75em;
  opacity: 0.5;
}

@media (max-width: 600px) {
  .event-block__paragraph--short-text {
    margin-top: 0;
    line-height: 1.25;
  }
}

.event-block__title {
  mix-blend-mode: lighten;
  margin-top: 0;
  line-height: 0.9;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media (min-width: 1024px) {
  .event-block__title--enlarged {
    margin-bottom: 0;
  }
}

.event-block__title-showname {
  display: block;
  font-size: 1.25em;
  margin-top: 0;
  letter-spacing: -1.75px;
  position: relative;
  line-height: 0.75em;
  text-shadow: 1px 1px #1e300dd9;
}
@media (min-width: 1024px) {
  .event-block__title-showname {
    font-size: 1.5em;
    line-height: 0.8em;
  }
}
.event-block__title-showname--blurred {
  opacity: 0.5;
  filter: blur(0.5px);
}

.event-block__title-showname::after {
  content: attr(data-short-text);
  letter-spacing: initial;
  display: block;
  max-width: 25ch;
  opacity: 0.8;
  font-size: 0.33em;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.1;
  text-shadow: none;
}
.event-block__title-showname--enlarged {
  position: static;
}
.event-block__title-showname--enlarged::after {
  display: none;
  content: '';
}

@media (max-width: 600px) {
  .event-block__title {
    font-size: 1.25rem;
  }
}

.event-block__title-location {
  width: 160px;
  text-align: right;
  line-height: 0.9;
  opacity: 0.7;
  margin-top: 0;
}
@media (min-width: 1024px) {
  .event-block__title-location {
    width: 340px;
    padding-left: 20px;
  }
}
.event-block__title-location.number-of-dates-2 {
  position: relative;
  top: -0.5em;
}
.event-block__title-location.number-of-dates-3 {
  position: relative;
  top: -0.75em;
}

.event-block__location-row {
  display: block;
}
.event-block__title-location--enlarged .event-block__location-row {
  display: inline-block;
}
.event-block__title-location--enlarged .event-block__location-row + .event-block__location-row {
  margin-left: 0.5em;
}
.event-block__title-location time {
  display: block;
  width: 100%;
  float: right;
  font-size: 0.66em;
  line-height: 1em;
  margin-top: 0.1em;
}
@media (min-width: 1024px) {
  [class*='enlarged'] .event-block__title-location time {
    /* width: 160px; */
  }
}

.event-block__dates {
  margin: 0;
  position: relative;
  line-height: 1;
  display: block;
}

@media (max-width: 600px) {
  .event-block__dates {
    position: static;
    display: inline-block;
    padding-left: 0;
    margin-left: 0;
    width: 50px;
    font-size: 0.9em;
    margin-top: 0.25em;
  }
  .event-block__dates::before {
    display: none;
  }
}

.event-block__sluit-enlarged-btn {
  display: none;
  filter: brightness(95%);
}
.event-block__sluit-enlarged-btn:hover {
  cursor: pointer;
  filter: brightness(100%);
}
.event-block__sluit-enlarged-btn--enlarged {
  display: block;
  font-size: 0.5rem;
  padding: 1em 2em;
  border: 0;
  background-color: var(--red);
  color: black;
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 12324242;
  text-align: center;
}
@media (max-width: 600px) {
  .event-block__sluit-enlarged-btn--enlarged {
    position: absolute;
  }
}
.event-block__sluit-enlarged-btn img {
  filter: invert(100%);
  width: 15px;
}
.event-block__sluit-enlarged-btn--sold-out {
  top: -50px;
  right: -16px;
}
@media (min-width: 600px) {
  .event-block__sluit-enlarged-btn--sold-out {
    top: -72px;
    right: -36px;
  }
}

/* #endregion HEADER */

/* #region PRICE AND LINK */

.event-block__price-link-wrapper {
  font-size: 1.33rem;
  padding: 0.5em 0.75em;
  font-weight: bold;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 900;
  min-width: 110px;
  text-align: center;
  text-decoration: none;
  opacity: 0.9;
}

.ticketemoji,
.event-block__price {
  background-blend-mode: hard-light;
}
.ticketemoji {
  padding: 0.33em 4em 0.33em 0.33em;
  background-color: var(--green);
  font-size: 1.5em;
  margin-right: -2.5em;
  margin-top: -1em;
  position: relative;
}

[class*='enlarged'] .ticketemoji::before {
  content: 'Kaarten';
  position: absolute;
  bottom: -87px;
  left: -40px;
  z-index: -1;
  font-size: 1.66em;
  color: #00ff70;
}
@media (min-width: 1024px) {
  [class*='enlarged'] .ticketemoji::before {
    content: 'Kaarten';
    position: absolute;
    bottom: 230px;
    left: 60px;
    z-index: -1;
    font-size: 1.33em;
    color: #00ff70;
    transform: rotate(90deg);
  }
}

.event-block__price {
  padding: 0.5em 2em 0.5em 0.66em;
  margin-left: -2em;
  vertical-align: middle;
  display: inline-block;
  background-blend-mode: hard-light;
  mix-blend-mode: difference;
  background-color: var(--pink);
  min-width: 90px;
  line-height: 1.5em;
}
.event-block__price--sold-out {
  padding: 0.33em 2em 0.33em 0.66em;
}

.event-block__price-link-wrapper--sold-out {
  font-size: 1em;
  padding: 1.5em 0.75em;
}

.event-block__price-link-wrapper--sold-out .ticketemoji {
  background-color: var(--pink);
  margin-right: -1.5em;
  line-height: 0.8;
}
.event-block__price-link-wrapper--sold-out .event-block__price {
  background-color: var(--green);
}

.event-block__venue-link--enlarged {
  position: relative;
  top: auto;
  font-size: 2.5rem;
  right: auto;
  transform: initial;
  margin-top: 1em;
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
}

/* #endregion HEADER AND LINK */

/* #region IMAGE */

.provide-dark-contrast {
  position: relative;
}
.provide-dark-contrast::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background: linear-gradient(
      245deg,
      rgba(38, 1, 1, 0.33) 5%,
      rgba(60, 10, 10, 0.6) 15%,
      rgba(68, 15, 58, 0.85) 80%,
      rgba(16, 4, 13, 0.85) 100%
    ),
    linear-gradient(
      181deg,
      rgba(16, 4, 13, 0.85) 0%,
      rgba(68, 15, 58, 0.45) 4%,
      rgba(68, 15, 58, 0) 6%,
      transparent 100%
    );
  mix-blend-mode: multiply;
  z-index: -1;
}
.provide-dark-contrast.provide-dark-contrast--variation-1::before {
  background: linear-gradient(245deg,
      rgba(10, 38, 1, 0.33) 5%,
      rgba(13, 60, 10, 0.6) 15%,
      rgba(15, 61, 68, 0.85) 80%,
      rgba(6, 16, 4, 0.85) 100%),
    linear-gradient(181deg,
      rgba(8, 16, 4, 0.85) 0%,
      rgba(15, 68, 40, 0.45) 4%,
      rgba(20, 68, 15, 0) 6%,
      transparent 100%);
}
.provide-dark-contrast.event-block--sold-out::before {
  background: linear-gradient(
      245deg,
      rgba(38, 1, 1, 0.33) 5%,
      rgba(60, 10, 10, 0.6) 15%,
      rgba(68, 15, 58, 0.85) 80%,
      rgba(16, 4, 13, 0.85) 100%
    ),
    linear-gradient(
      181deg,
      rgba(16, 4, 13, 0.85) 0%,
      rgba(68, 15, 58, 0.45) 4%,
      rgba(68, 15, 58, 0) 6%,
      transparent 100%
    ),
    linear-gradient(
      45deg,
      transparent 0%,
      transparent 5%,
      var(--red) 6%,
      var(--red) 10%,
      transparent 11%,
      transparent 15%,
      var(--red) 16%,
      var(--red) 20%,
      transparent 21%,
      transparent 25%,
      var(--red) 26%,
      var(--red) 30%,
      transparent 31%,
      transparent 35%,
      var(--red) 36%,
      var(--red) 40%,
      transparent 41%,
      transparent 45%,
      var(--red) 46%,
      var(--red) 50%,
      transparent 51%,
      transparent 55%,
      var(--red) 56%,
      var(--red) 60%,
      transparent 61%,
      transparent 65%,
      var(--red) 66%,
      var(--red) 70%,
      transparent 71%,
      transparent 75%,
      var(--red) 76%,
      var(--red) 80%,
      transparent 81%,
      transparent 85%,
      var(--red) 86%,
      var(--red) 90%,
      transparent 91%,
      transparent 95%,
      var(--red) 96%,
      var(--red) 100%
    ),
    linear-gradient(
      120deg,
      transparent 0%,
      transparent 5%,
      var(--anthracite) 6%,
      var(--anthracite) 10%,
      transparent 11%,
      transparent 15%,
      var(--anthracite) 16%,
      var(--anthracite) 20%,
      transparent 21%,
      transparent 25%,
      var(--anthracite) 26%,
      var(--anthracite) 30%,
      transparent 31%,
      transparent 35%,
      var(--anthracite) 36%,
      var(--anthracite) 40%,
      transparent 41%,
      transparent 45%,
      var(--anthracite) 46%,
      var(--anthracite) 50%,
      transparent 51%,
      transparent 55%,
      var(--anthracite) 56%,
      var(--anthracite) 60%,
      transparent 61%,
      transparent 65%,
      var(--anthracite) 66%,
      var(--anthracite) 70%,
      transparent 71%,
      transparent 75%,
      var(--anthracite) 76%,
      var(--anthracite) 80%,
      transparent 81%,
      transparent 85%,
      var(--anthracite) 86%,
      var(--anthracite) 90%,
      transparent 91%,
      transparent 95%,
      var(--anthracite) 96%,
      var(--anthracite) 100%
    );
}

.event-block__image {
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}

.event-block__image--sold-out {
  filter: brightness(50%);
}

.event-block--enlarged .event-block__image {
  filter: brightness(0.33) blur(1px);
}

@media (max-width: 600px) {
  .event-block--image {
    left: 50%;
    width: 50%;
  }
  .event-block--image--enlarged {
    width: 100%;
    left: 0;
  }
}

/* #endregion IMAGE */

/* #region FIRST OF MONTH TIME */

.event-block--first-of-month {
  border-left-width: 150px;
  border-left-style: solid;
  border-left-color: #0f110e;
  overflow-x: initial;
}

@media (max-width: 600px) {
  .event-block--first-of-month {
    border-left-width: 0;
    border-top: 70px solid #0f110e;
  }
}

.event-block__first-of-month-time {
  position: absolute;
  font-size: 2.5em;
  color: white;
  left: -212px;
  top: 142px;
  width: 270px;
  text-align: right;
  transform: rotate(-90deg);
  z-index: 2;
  font-weight: 900;
  letter-spacing: 0.1em;
  line-height: 0.95;
  text-transform: capitalize;
}

@media (max-width: 1024px) {
  .event-block--first-of-month-time {
    border-left-width: 100px;
  }
  .event-block__first-of-month-time {
    left: -177px;
    top: 129px;
    width: 250px;
  }
}

@media (max-width: 600px) {
  .event-block--first-of-month-time {
    border-left: none;
    border-top-width: 60px;
    border-top-style: solid;
    border-top-color: #0f110e;
  }
  .event-block__first-of-month-time {
    font-size: 1.5em;
    right: auto;
    top: -70px;
    width: 100%;
    transform: rotate(0);
    left: 0px;
    line-height: 68px;
    text-align: left;
    padding: 0 20px;
    background-color: rgba(255, 255, 255, 0.02);
    border-top: 1px solid rgba(255, 255, 255, 0.05);
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }
}

.event-block--first-of-month.event-block--enlarged {
  border: 0;
}
.event-block--enlarged .event-block__first-of-month-time {
  display: none;
}
/* #endregion FIRST OF MONTH TIME */

/* #region ENLARGED UNIVERSAL */

.void-container-for-enlarged {
  display: none;
}

.void-container-for-enlarged > *:first-child {
  margin-top: 0;
}
.void-container-for-enlarged > *:last-child {
  margin-bottom: 0;
}

.void-container-for-enlarged--enlarged {
  display: block;
}

.provide-dark-contrast--enlarged::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background: rgba(0, 0, 0, 0.4);
  mix-blend-mode: darken;
  z-index: -1;
}
.void-container-for-enlarged a {
  text-decoration: none;
  color: unset;
}
.void-container-for-enlarged a:hover {
  cursor: pointer;
}
.long-html__social {
  margin: 30px 0;
}
.long-html__social-list {
  list-style-type: none;
  padding: 0 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: -10px;
}
.long-html__social-list-item {
  padding: 0;
  margin: 10px 10px 10px 10px;
}
.long-html__social a.long-html__social-list-link {
  padding: 0.33em 0.66em;
  background-color: var(--pink);
  color: var(--green);
  font-weight: bold;
  font-size: 1.25rem !important;
  line-height: 1.25rem !important;
}
a.long-html__social-list-link:hover {
  filter: brightness(80%);
}

/* .long-html__social-list-link[href*='fb.me']::before,
.long-html__social-list-link[href*='facebook']::before {
  content: 'FB '
} */

.long-html__music-videos {
  margin: 20px -20px;
  width: calc(100% + 40px);
  display: block;
  background-color: #08081ab8;
  padding: 0px 20px 40px 20px;
  box-shadow: inset -10px 3px 8px 4px rgba(3, 3, 3, 0.33);
  border-top: 20px solid var(--blue);
  position: relative;
  margin-top: 50px;
}
@media (min-width: 1024px) {
  .long-html__music-videos {
    margin: 100px 0 0 -36px;
    width: 732px;
    display: block;
    background-color: #08081ab8;
    padding: 0px 20px 40px 20px;
    box-shadow: inset -10px 3px 8px 4px rgba(3, 3, 3, 0.33);
    border-top: 20px solid var(--blue);
    position: relative;
  }
}

.long-html__music-videos::before {
  content: 'Muziek';
  position: absolute;
  top: -85px;
  left: -25px;
  z-index: 0;
  font-size: 6.5em;
  color: #00ff70;
  font-weight: 900;
  mix-blend-mode: difference;
}
@media (min-width: 1024px) {
  .long-html__music-videos::before {
    top: -125px;
    left: -200px;
  }
}

.long-html__music-videos [class*='iframe-wrapper'] + [class*='iframe-wrapper'] {
  margin-top: 20px;
}

.iframe-wrapper-16-9 {
  position: relative;
  padding-bottom: calc(56.25% - (40px * 0.5625 + 22px));
  width: calc(100% - 40px);
}
.iframe-wrapper-16-9:nth-child(odd):last-child {
  padding-bottom: 56.25%;
}
.iframe-wrapper-152px {
  flex-basis: 50%;
  flex-grow: 1;
  position: relative;
  padding-bottom: 152px;
}
.iframe-wrapper-16-9 > iframe,
.iframe-wrapper-152px > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* #endregion ENLARGED UNIVERSAL */

/* #region ENLARGED TYPEOGRAPHY */

.void-container-for-enlarged h1,
.void-container-for-enlarged h2 {
  font-size: 1.3em;
  margin: 0;
  text-transform: lowercase;
}
.void-container-for-enlarged h1::first-letter {
  text-transform: capitalize;
}

.void-container-for-enlarged h1:first-child,
.void-container-for-enlarged h2:first-child {
  margin-bottom: 1em;
}
.event-block__main .event-block__header-text--short-text.event-block__paragraph--enlarged {
  font-size: 1.1em;
  font-weight: bold;
  color: #f5f5f5;
  opacity: 1;
  max-width: 30ch;
}
.event-block__main .long-html__text p,
.event-block__main .long-html__text ul,
.event-block__main .long-html__text span,
.event-block__main .long-html__text a,
.event-block__main .long-html__text > div {
  font-size: 0.8em;
  max-width: 40ch;
  line-height: 1.05em;
}
.event-block__main .long-html__text p {
  margin-top: 0.25em;
}
.event-block__main .long-html__text p *,
.event-block__main .long-html__text ul *,
.event-block__main .long-html__text span *,
.event-block__main .long-html__text a *,
.event-block__main .long-html__text > div * {
  font-size: inherit !important; /* 4 great justice */
}

.event-block__main .long-html__text h2,
.event-block__main .long-html__text h3 {
  font-size: 1.66rem;
  margin-bottom: 0.25em;
  line-height: 1;
  font-variant: small-caps;
}
.event-block__main .long-html__text h4 {
  font-size: 1.25rem;
  margin-bottom: 0.25em;
  line-height: 1;
  font-variant: small-caps;
}

@media (min-width: 1024px) {
  .event-block__main .long-html__text p,
  .event-block__main .long-html__text ul,
  .event-block__main .long-html__text span,
  .event-block__main .long-html__text a {
    font-size: 0.66em;
    max-width: 50ch;
    line-height: 1.15em;
  }
}

/* #endregion ENLARGED TYPEOGRAPHY */

/* #region ENLARGED SOCIALS */

/* #endregion ENLARGED SOCIALS */

/* #region MAIN */

.event-block__main {
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: clip;
  padding-right: 60px;
  margin-right: -60px;
}
.event-block__main--enlarged {
  overflow-y: visible;
  overflow-x: visible;
}

.event-block__main ul,
.event-block__main h1,
.event-block__main h2,
.event-block__main h3,
.event-block__main h4,
.event-block__main h5 {
  max-width: 55ch;
}

.event-block__hide-sold-out {
  padding: 0.5em 1em 0.5em 1em;
  background-blend-mode: hard-light;
  mix-blend-mode: difference;
  background-color: var(--pink);
  min-width: 90px;
  line-height: 1em;
  border: 0;
  font-weight: 900;
  text-align: center;
  color: white;
  font-size: 0.5em;
  display: inline-block;
  width: 120px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  opacity: 0.25;
}
.event-block__hide-sold-out:hover {
  opacity: 1;
  cursor: pointer;
}
/* #endregion MAIN */

/* #region FOOTER */
.event-block__footer {
  text-align: right;
  margin: 0 -20px -20px -20px;
  width: calc(100% + 40px);
}
.event-block__footer--sold-out {
  filter: brightness(0.5);

  font-size: 0.66em;
}
.event-block__footer--enlarged {
  text-align: left;
  background-color: var(--allmost-black);
  margin-top: 0;
  margin-bottom: 0;
  padding: 40px 20px 80px 20px;
  box-shadow: inset -10px 2px 8px 2px rgba(255, 255, 255, 0.12);
  border-top: 1px solid #ffffff1c;
}

@media (min-width: 1024px) {
  .event-block__footer--enlarged {
    position: absolute;
    top: 490px;
    right: 0px;
    width: 450px;
    background-color: transparent;
    box-shadow: none;
    border: none;
    transform: translateX(50%) rotate(0deg);
    padding-bottom: 0;
  }
}

/* #endregion FOOTER */

/* #region MORE BLOCKS */

.event-block__more-blocks {
  background-color: var(--pink);
  color: white;
  padding: 0.5em 0.75em;
  margin-top: 0;
  display: inline-block;
  font-size: 1.5em;
  mix-blend-mode: hard-light;
  flex-grow: 0;
  text-transform: uppercase;
  font-weight: 900;
  width: 100%;
  border: 0;
}
.event-block__more-blocks:hover {
  cursor: pointer;
}

/* #endregion MORE BLOCKS */
