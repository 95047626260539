:root {
  --green: #b3ff4d;
  --pink: #f325e6;
  --anthracite: #0f110e;
  --allmost-black: #0a0707;
  --allmost-black-2: #0f110e;
  --red: rgba(222, 46, 15, 0.881);
  --blue: #2528f3;
}

.green-color {
  color: var(--green);
}
.pink-color {
  color: var(--pink);
}
.anthracite-color {
  color: var(--anthracite);
}
.allmost-black-color {
  color: var(--allmost-black);
}
.allmost-black-2-color {
  color: var(--allmost-black-2);
}
.red-color {
  color: var(--red);
}
.blue-color {
  color: var(--blue);
}
